@import '@/styles/config';

.bookCard {
  display: grid;
  grid-template-columns: auto 1fr auto;
  gap: 0.5rem;
}

.bookCardImageContainer {
  width: 62px;
  height: 70px;
  position: relative;
}

.bookCardImage {
  object-fit: cover;
  border-radius: $borderRadius;
}

.avatarContainer {
  width: 26px;
  height: 26px;
  position: relative;
}

.avatar {
  object-fit: cover;
  border-radius: 100%;
}

.checkoutItemContainer {
  background-color: $grey-200;
  border: 1px solid $grey-300;
  border-radius: $borderRadius;
  padding: 1rem;
}

.offer-applied {
  width: 1rem;
  height: 1rem;

  path {
    fill: map-get($map: $colors, $key: green);
  }

}