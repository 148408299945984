.accordion {
  overflow: hidden;
}

.header {
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  justify-content: center;
  gap: 1rem;

  &:hover {
    cursor: pointer;
  }
}

.chevron {
  cursor: pointer;
}

.content {
  max-height: 0;
  opacity: 0;
  overflow: hidden;
  transition: max-height 0.3s ease, opacity 0.3s ease;
}

.content.open {
  max-height: 1000px;
  opacity: 1;
}

.contentInner {
  padding-top: 1rem;
}
