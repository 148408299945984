@import '../../config';
@import '../../mixins/typography';

.product-details ~ .product-details {
  width: 100%;
  margin-top: 2rem;
}

.product-details {
  h2 {
    @include heading();
    margin-bottom: 0.5rem;
  }

  h3 {
    @include type($color: copy);
    margin-bottom: 0.5rem;
    line-height: 1.5;
  }

  h3.subcategory {
    @include type($font: copyBold, $size: base, $color: copyDark);
    line-height: 1.5;
  }

  p {
    @include copy();
    width: 100%;
    word-wrap: break-word;
  }

  h4 {
    @include copy();
    @include type($font: copyBold);
    display: flex;
    align-items: center;

    ~ h4 {
      margin-top: 0.25rem;
    }
  }

  &-icon {
    padding: 0.5rem;
    width: 2rem;
    height: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $primary-100;
    border-radius: 1rem;
    margin-right: 0.5rem;

    svg {
      width: 1rem;
      height: 1rem;
    }
  }

  span {
    @include type($font: copy);
    margin-right: 0.5rem;
  }
}

.click-text p {
  @include type($size: base, $color: primary);
  cursor: pointer;
}
