$spacing: (
  sitePadding: 1rem,
  borderRadius: 1rem,
  borderRadiusLarge: 2rem,
  navHeight: 81px,
  mobileNavHeight: 115px,
  categoryHeight: 50px,
  siteMaxWidth: 1330px,
  bottomMobileNavHeightClearance: 105px,
  bottomMobileNavHeight: 64px,
  adminSidebarWidth: 20rem,
);

$sitePadding: map-get($spacing, sitePadding);
$borderRadius: map-get($spacing, borderRadius);