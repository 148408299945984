@import "@/styles/config";
@import "@/styles/mixins/typography";
.banner {
  height: 100%;
}

.container {
  margin-bottom: 1rem;
}

.icon {
  margin-right: 0.5rem;
}

.callout {
  margin-bottom: 2rem;
  margin-top: 2rem;
}

.callout-multiple-books-icon {
  width: 50px;
  height: 50px;
  align-self: center;
  justify-self: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 1rem;
  background-color: white;
  border-radius: 50%;
}