@import "../config";

.container {
  width: 100%;
  background-color: map-get($map: $colors, $key: borderLight);
  height: 8px;
  position: relative;
  border-radius: map-get($map: $spacing, $key: borderRadius)
}

.bar {
  position: absolute;
  height: 100%;
  background-color: map-get($map: $colors, $key: primary);
  transition: width 0.5s ease-in-out;
  border-end-start-radius: map-get($map: $spacing, $key: borderRadius);
  border-start-start-radius: map-get($map: $spacing, $key: borderRadius);
}

.filled-bar {
  border-start-end-radius: map-get($map: $spacing, $key: borderRadius);
  border-end-end-radius: map-get($map: $spacing, $key: borderRadius);
}