@import '../config';
@import '../mixins/typography';
@import '../mixins/box-shadow';
@import '../mixins/scroll';
@import '../mixins/loading';

.carousel {
  width: 100%;

  @include breakpoint(large, min) {
    padding-bottom: 0;
  }

  &-swiper {
    --swiper-navigation-color: transparent;
    --swiper-pagination-color: #ffffff;

    width: 100%;
    min-width: 0;
    height: auto;

    @include breakpoint(large, min) {
      --swiper-navigation-color: #ffffffd1;
    }

  }

  &-swiper {
    --swiper-navigation-color: transparent;
    --swiper-pagination-color: #ffffff;
    min-width: 0;
    height: auto;

    @include breakpoint(large, min) {
      --swiper-navigation-color: #ffffffd1;
    }

  }

  &-main-image {
    @include skeleton();
    border-radius: 0;

    @include breakpoint(large, min) {
      border-radius: map-get($map: $spacing, $key: borderRadius);
      margin-bottom: 0.25rem;
      overflow: hidden;
    }
  }

  &-thumbnail-container {
    @include scrollX();
    text-align: center;
    white-space: nowrap;
    max-width: 100%;
    margin: 0 auto;
    margin-top: 0.75rem;

    &::-webkit-scrollbar {
      height: 5px !important;
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background: rgba(map-get($map: $colors, $key: primary), 0.5) !important;
    }

    @include breakpoint(large, max) {
      display: none;
    }
  }

  $scroll-button-size: 2.75rem;

  &-scroll-button {
    @include box-shadow();
    @include heading();
    width: $scroll-button-size;
    height: $scroll-button-size;
    color: map-get($map: $colors, $key: primary);
    align-items: center;
    justify-content: center;
    transition: all 0.5s;
    z-index: 2;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: map-get($map: $colors, $key: foreground);
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    &--left {
      left: (calc($scroll-button-size / 3));
      padding-left: 0;
    }

    &--right {
      right: (calc($scroll-button-size / 3));
      padding-right: 0;
    }
  }

  &-thumbnail {
    transition: all 0.25s;
    border-radius: map-get($map: $spacing, $key: borderRadius);
    overflow: hidden !important;
    cursor: pointer;
    position: relative;
    margin-right: 0.75rem;
    display: inline-block;
    min-width: 4rem;
    min-height: 4rem;
    width: 4rem;
    height: 4rem;

    &:last-of-type {
      margin-right: 0;
    }

    @include breakpoint(large, max) {
      margin-right: 0.5rem;
    }

    img {
      min-width: 4rem;
      min-height: 4rem;
      width: 4rem;
      height: 4rem;

      @include breakpoint(large, max) {
        min-width: 3.5rem;
        width: 3.5rem;
        min-height: 3.5rem;
        height: 3.5rem;
      }
    }

    &:hover {
      @include box-shadow();
    }

    &--active {
      &:after {
        content: '';
        position: absolute;
        min-width: 100%;
        min-height: 100%;
        top: 0;
        left: 0;
        background-color: rgba(map-get($map: $colors, $key: primary), 0.5);
        z-index: 2;
      }
    }
  }
}