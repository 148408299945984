@import '@/styles/config';

.checkoutGrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;

  @include breakpoint(medium, max) {
    grid-template-columns: 1fr;
  }
}

.paymentCard {
  padding: $sitePadding;
}

.nextButtonContainer {
  width: 75px;
}

.heading {
  @include breakpoint(medium, max) {
    display: none;
  }
}