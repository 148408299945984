@import '@/styles/config';
@import '@/styles/mixins/typography';

.headingContainer {
  @include heading();
}

.paragraphContainer {
  @include type($size: base, $font: copy, $color: copyDark);
}

.asCopy {
  @include copy();
}

.link {
  &:hover {
    color: map-get($map: $colors, $key: primary);
  }
}

.strikeThru {
  text-decoration: line-through;
}

.underline {
  text-decoration: underline;
}

/**
  * Colors
  */
.primary {
  color: map-get($map: $colors, $key: primary);
}

.copy {
  color: map-get($map: $colors, $key: copy);
}

.copyDark {}

.white {
  color: $grey-100;
}

.green {
  color: $success-700;
}

.red {
  color: $error-700;
}

/**
  * Font Families
  */
.light {
  font-family: map-get($fonts, copyLight);
}

.bold {
  font-family: map-get($fonts, copyBold);
}

.normal {}

/**
  * Font Sizes
  */

.xtratiny {
  @include font-size(xtratiny);
}

.tiny {
  @include font-size(tiny);
}

.small {
  @include font-size(small);
}

.base {
  @include font-size(base);
}

.medium {
  @include font-size(medium);
}

.large {
  @include font-size(large);
}

.jumbo {
  @include font-size(jumbo);
}