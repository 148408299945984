@import '@/styles/config/colors';
@import '@/styles/config/spacing';

.container {
  background-color: $primary-700;
  width: 100%;
  padding: map-get($map: $spacing, $key: sitePadding);
  height: 200px;
  border-radius: map-get($map: $spacing, $key: borderRadius);
  position: relative;

  .image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: map-get($map: $spacing, $key: borderRadius);
    z-index: 1; // Lower z-index so it's behind the children

    img {
      border-radius: map-get($map: $spacing, $key: borderRadius);
    }
  }

  .content {
    position: relative;
    z-index: 2; // Higher z-index to be on top of the image
    height: 100%;
  }
}