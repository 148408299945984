@import '../config';

.full-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  .stars {
    display: flex;
    align-items: center;
  }

  .reviews {
    margin-left: 0.5rem;
  }

  .hidden {
    display: none;
  }
}
.author-carousel-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  .stars {
    display: flex;
    align-items: center;
  }

  .rating {
    margin-left: 0.25rem;
  }

  .reviews {
    margin-left: 0.5rem;
    display: flex;
    flex-direction: row;
    color: map-get($map: $colors, $key: copy);
    gap: 0.25rem;
  }

  .hidden {
    display: none;
  }
}
.no-reviews {
  color: map-get($map: $colors, $key: copy);
}