@import '@/styles/config';
@import '@/styles/mixins/box-shadow';


.container {
  padding: map-get($map: $spacing, $key: sitePadding);
  @include box-shadow();
  border-radius: map-get($map: $spacing, $key: borderRadius);

  display: grid;
  grid-template-areas:
    'image info pricing'
    'image summary pricing'
    'image summary pricing';
  grid-template-columns: auto 1fr auto;
  grid-template-rows: auto;
  column-gap: 1rem;

  @include breakpoint(large, max) {
    grid-template-areas:
      'image info'
      'image summary'
      'pricing pricing';
    grid-template-columns: auto 1fr;
  }

  @include breakpoint(small, max) {
    grid-template-areas:
      'image info'
      'image info'
      'summary summary'
      'pricing pricing';
    grid-template-columns: auto 1fr;
  }
}

.image {
  grid-area: image;
  position: relative;
  height: 215px;
  width: 215px;

  @include breakpoint(small, max) {
    height: 120px;
    width: 120px;
  }

  img {
    object-fit: cover;
    border-radius: map-get($map: $spacing, $key: borderRadius);
  }
}

.shelf {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
}

.mainInfo {
  grid-area: info;

}

.summary {
  grid-area: summary;

  @include breakpoint(small, max) {
    margin-top: 1rem;
  }
}

.pricingInfo {
  grid-area: pricing;
  display: grid;
  grid-template-areas:
    'price'
    'sales'
    'cart';
  grid-template-columns: auto;
  grid-auto-rows: min-content;

  @include breakpoint(large, max) {
    grid-template-areas:
      'price sales cart';
    grid-template-columns: 1fr 1fr 1fr;
  }

  @include breakpoint(small, max) {
    grid-template-areas:
      'price sales'
      'cart cart';
    grid-template-columns: 1fr 1fr;
  }
}

.readMore {
  cursor: default;
}

.cart {
  grid-area: cart;
  width: 220px;

  @include breakpoint(large, max) {
    justify-self: end;
    align-self: center;
    margin-top: 1rem;
  }

  @include breakpoint(small, max) {
    width: 100%;
  }
}

.price {
  grid-area: price;
  margin-bottom: 1rem;

  @include breakpoint(large, max) {
    align-self: center;
    margin-bottom: 0;
    margin-top: 1rem;
  }
}

.sales {
  grid-area: sales;
  margin-bottom: 1rem;

  @include breakpoint(large, max) {
    align-self: center;
    margin-bottom: 0;
    margin-top: 1rem;
  }
}