$primary-100: #e9eeff;
$primary-300: #d6e4ff;
$primary-500: #3b61eb;
$primary-700: #243470;

$success-100: #e8f5d9;
$success-300: #c5e79f;
$success-500: #519e2d;
$success-700: #2f7e09;

$warning-100: #fff2d9;
$warning-300: #ffe1a5;
$warning-500: #ffcd69;
$warning-700: #945c16;

$error-100: #fae9ea;
$error-300: #ec5653;
$error-500: #d52c24;
$error-700: #b71c1c;

$purple-100: #ece0ff;
$purple-300: #bca5f6;
$purple-500: #7b46eb;
$purple-700: #643ab7;

$grey-100: #ffffff;
$grey-200: #fbfcfe;
$grey-300: #f4f4f7;
$grey-400: #e9e9ed;
$grey-500: #e0e2ea;
$grey-600: #a0a2ae;
$grey-700: #707487;
$grey-800: #4e515e;
$grey-900: #393b41;
$grey-1000: #1d1d21;

$lighterGrey: lighten(#a5abc0, 20%);

$colors: (
  primary: #3b61eb,
  foreground: #ffffff,
  background: $grey-200,
  copy: #6b7790,
  copyLight: #a5abc0,
  gray: #6b7790,
  copyDark: #0b2b4a,
  lightGray: #a5abc0,
  light: #edeff3,
  borderLight: $grey-400,
  yellow: #f2ac13,
  darkYellow: #c78800,
  green: #4fac68,
  lighterGrey: $lighterGrey,
  primaryBackground: #f1f4ff,
  error: $error-500,
);